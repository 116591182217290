import { Pipe, PipeTransform } from "@angular/core";
import tr from "./tr.json"
import en from "./en.json"

@Pipe({
    name: "translate"
})
export class LanguagePipe implements PipeTransform {

    private usedLang: string;
    setLang() {
        let lang = localStorage.getItem("lang");
        if (!lang) {
            lang = navigator.language;
            localStorage.setItem("lang", lang);
        }
        this.usedLang = lang;
    }

    transform(lang: string, filter: string) {
        this.setLang();
        if (this.usedLang.includes("en"))
            return tr[lang][filter];
        else
            return en[lang][filter];
    }

}