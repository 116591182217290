<footer>
    <div class="blackarea">
        <div class="container">
            <div class="row">
              <div class="half">
                <div class="other-40"><br>
                    <p class="nomp fheight w-700 ">{{'Footer' | translate:'F-title-1'}}</p>
                    <p>{{'Footer' | translate:'Address'}}</p>
                </div>
                <div class="other-40">
                    <p class="fheight"></p>
                    <p>{{'Footer' | translate:'Address2'}}</p>
                </div>
              </div>
              <div class="half">
                <div class="other-35"><br>
                    <p class="nomp fheight w-700">{{'Footer' | translate:'F-title-2'}}</p>
                    <p>
                        E Posta: 
                        <a href="mailto:{{'Footer' | translate:'Email'}}">{{'Footer' | translate:'Email'}}</a>
                        <br/>
                        Telefon: 
                        <a href="tel:{{'Footer' | translate:'Phonee'}}">{{'Footer' | translate:'Phone'}}</a>
                     </p>
                </div>
                
              </div>
            </div>
        </div>
    </div>
    <div class="grayarea">
        <div class="container">
            <div class="row">
                <p class="w-100">
                    <span class="left">{{'Footer' | translate:'PP'}}</span>
                    <span class="right">
                        <a href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                        <a href=""><i class="fa fa-instagram" aria-hidden="true"></i></a>
                    </span>
                </p>
            </div>
        </div>
    </div>
</footer>

