import { Component, OnInit } from '@angular/core';
@Component({
    templateUrl: "./footer.component.html",
    selector: "get-footer",
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    ngOnInit() {
    }
    constructor(
    ){}
     

}
